<template>
  <div style="background:#fff;">
    <div class="remind flex flex-center">
      <div>任务名称</div>
      <input type="text" placeholder="请填写" />
    </div>
    <div class="remind flex flex-center">
      <div>时间选择</div>
      <div class="flex flex-center">
        <div>7:00</div>
        <img class="remind-icon" src="../assets/imgs/arrow.png" alt="" />
      </div>
    </div>
    <div class="remind-textarea">
      <div class="remindText-title">提醒说明</div>
      <textarea placeholder="请填写提醒说明"></textarea>
    </div>
    <div class="rulesView">
      <div class="rulesView-title">上传附件</div>
      <img class="rulesView-add" src="../assets/imgs/add.png" alt="" />
    </div>




        <!-- 底部固定 -->
    <div class="bottom fixed flex">
      <div class="bottom-add flex-item_center" @click="router('/CreateFollow')">保存</div>
    
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.remind {
  margin: 0 auto;
  width: 670px;
  background: #fff;
  border-bottom: 1px solid #ededf7;
  padding: 64px 0 26px 0;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  justify-content: space-between;
  color: #66728f;
}

.remind input {
  outline: none;
  border: 0;
  text-align: right;
}

.remind-icon {
  width: 12px;
  height: 20px;
  margin-left: 10px;
}

.remind-textarea {
  margin: 0 auto;
  width: 670px;
  background: #fff;
  border-bottom: 1px solid #ededf7;
  padding: 64px 0 26px 0;
}

.remindText-title {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
}

textarea {
  outline: none;
  border: 0;
  width: 670px;
  margin-top: 38px;
  height: 110px;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #101f48;
}

.rulesView {
  width: 670px;
  padding: 52px 0 88px 0;
  margin: 0 auto;
}

.rulesView-title {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
  margin: 0 0 37px 0;
}

.rulesView-add {
  width: 180px;
  height: 180px;
  background: #ededf7;
  border-radius: 16px 16px 16px 16px;
}

.bottom {
  width: 100%;
  bottom: 0;
  left: 0;
  height: 151px;
  background: #ffffff;
  justify-content: center;
  border-top: 1px solid #c3c3c3;
}

.bottom-add {
  width: 670px;
  height: 80px;
  background: #0558f4;
  border-radius: 16px 16px 16px 16px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin-top: 15px;
}
</style>
